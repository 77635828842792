
import AppleAppSiteJSON from '../../assets/json/apple-app-site-association.json'

const AppleAppSite = (props) => {

    const jsonData = JSON.stringify(AppleAppSiteJSON, undefined, 2)

    return(

        <pre>{jsonData}</pre>
    )

}
export default AppleAppSite;