import React, { Component } from 'react'

import Footer from './../components/footer';
import TermsHeader from './../components/terms-header';



class Privacy extends Component {

  constructor(props){
    super(props)

    this.state = {
      dat: {},
      bat: ''
    }
  }

  render() {
    return (
      <>
      <TermsHeader />
      <div className="terms-header position-relative overflow-hidden text-center shadow-lg privacy-banner">
          {/* <div className="bg-image-two " style={{backgroundImage: `url(${banner2})`, backgroundRepeat: 'no-repeat', backgroundPosition: '50% 75%'}} ></div> */}
          <div className="col-md-6 p-lg-6 mx-auto banner-text-grey" style={{marginTop: "175px"}} >
            <h1 className="display-4 fw-normal banner-header">Privacy Policy</h1>
            {/* <a class="btn btn-outline-secondary" href="#">Coming soon</a> */}
          </div>
      </div>

      <section className='overflow-hidden' style={{paddingBottom:"80px", paddingTop:"40px", marginTop: "100px"}}>
        <div className='col-12 w-max row reverse-flex-responsive' /*</section>style={{paddingTop:!this.props.mobile?"70px":"80px"}}*/>
          <h2 className='terms-title'>TapNPay and neoRide Privacy Policy</h2>
            <p>neoRide and its affiliates (<b>“TapNPay/neoRide,” “we,” “our,” and/or “us”</b>) value the privacy of individuals who use our services (collectively, our “Services”). This privacy policy (“Privacy Policy”) explains how we collect, use, and share information from neoRide users (“Users,” “your” and/or “you”). By using our Services, you agree to the collection, use, disclosure, and procedures this Privacy Policy describes. Beyond the Privacy Policy, your use of our Services is also subject to our Terms and Conditions.
            </p>
          <h3 className='section-break'>Information We Collect</h3>
            <p>We may collect a variety of information from or about you from various sources, as described below.</p>
            <p>If you do not provide your information when requested, you may not be able to use our Services if that information is necessary to provide you with our Services or if we are legally required to collect it.</p>
          <h5 className='subsection-break'>A. Information You Provide to Us.</h5>
            <p><b>Registration and Profile Information.</b> Our Services allow you to pay tolls and other transportation charges owed to operators of ATFOs and OTSPs using a Payment Method chosen by you during Account Registration. When you register to use our Services, we may receive your address, your mobile phone number from an eligible Carrier, email address, and your vehicle’s license plate numbers (“LPNs”), depending on your form of registration. Additionally, you may provide us with toll transaction and other vehicle travel information. If you are unable to enroll in our Services because the information you provide does not match the information maintained by the ATFO or other transportation service providers, we may maintain any information that you provide, including your mobile phone number, and use this information for any purposes including business and marketing purposes unless you choose to opt-out as permitted by this Privacy Policy. </p>
            <p><b>Payment Information.</b> When you use our Services to make payments, we allow you to select a prepaid or postpaid payment option utilizing several Payment Methods including but not limited to; Apple Pay, Google Pay, Debit Cards, Credit Cards, and PayPal. We do not collect or store any credit card information on our servers.  </p>
            <p><b>Communications.</b> If you contact us directly, we may receive additional information about you. For example, when you contact us for customer support, we may receive your name, email address, phone number, the contents of any messages or attachments that you may send to us, and other information you choose to provide.</p>
          <h5 className='subsection-break'>B. Information We Collect When You Use Our Services.</h5>
            <p><b>Device Information.</b> We receive information about the device and software you use to access our Services, including internet protocol (“IP”) address.</p>
            <p><b>Usage Information.</b> To help us understand how you use our Services and to help us improve them, when you use our Services, we automatically receive information about your interactions with our Services, like the pages or other content you view, pages you request, the websites you visited before using our Services, and the dates, times and durations of your visits.</p>
            <p><b>Location Information.</b> When you use our SMS based Services, we infer the general physical location of your device and the geographic regions of our Users. For example, your IP address may indicate your general geographic region.  When utilizing our mobile application-based Services, we leverage device map features to identify location and provide PUSH notifications where applicable (i.e. nearby parking covered with your neoRide account)</p>
            <p><b>Information from Cookies and Similar Technologies.</b> We and third-party partners collect information using cookies, pixel tags, or similar technologies. Our third-party partners, such as analytics and advertising partners, may use these technologies to collect information about your online activities over time and across different services. Cookies are small text files containing a string of alphanumeric characters. We may use both session cookies and persistent cookies. A session cookie disappears after you close your browser. A persistent cookie remains after you close your browser and may be used by your browser on subsequent visits to our Services.</p>
            <p>Please review your web browser’s “Help” file to learn the proper way to modify your cookie settings. Please note that if you delete or choose not to accept cookies from our Services, you may not be able to utilize the features of our Services to their fullest potential.</p>
          <h5 className='subsection-break'>C. Information We Receive from Third Parties.</h5>
            <p><b>Associated Transportation Facility Operators.</b> Our Services are operated pursuant to an arrangement we have with the applicable ATFO and OTSPs. By using our Services, you agree that we may receive the following information concerning you, your vehicle, or your account from the applicable ATFO or OTSPs: toll transactions, license plate number and state, billing invoices, location of the toll collection device where charges were incurred, and other information relevant to the provision of our Services. If we receive from an ATFO or OTSP any information that you have not otherwise provided to us regarding (a) motor vehicle registration or (b) other information derived from a license plate on a vehicle using an ATFO toll facility or OTSP, that information will not be used by us or, if we disclose or make it available to the applicable ATFO, our vendors, service providers, or billing entities, by such entities for purposes other than: (1) the provision of our Services; (2) toll collection and toll collection enforcement; (3) law enforcement purposes on request by a law enforcement agency; and (4) as otherwise provided for under this Privacy Policy.</p>
            <p><b>Other Third Parties.</b> We may receive additional information about you from third parties such as data or marketing partners and combine it with other information we have about you.</p>
          <h3 className='section-break'>How We Use the Information We Collect</h3>
          <p>We use the information you provide to us or that we collect when you use our Services:</p>
          <div className='indent'>
            <ul>
              <li>To provide, maintain, improve, develop, and enhance our products and services;</li>
              <li>To understand and analyze how you use our Services and develop new products, services, features, and functionality;</li>
              <li>To communicate with you, provide you with updates and other information relating to our Services, provide information that you request, respond to comments and questions, and otherwise provide customer support;</li>
              <li>For marketing purposes, such as developing and providing promotional and advertising materials that may be useful, relevant, valuable or otherwise of interest to you;</li>
              <li>To generate deidentified (personally identifiable information is removed) reports for business purposes outlined in this section;</li>
              <li>To send you text messages;</li>
              <li>To facilitate transactions and payments;</li>
              <li>To find and prevent fraud, and respond to trust and safety issues that may arise;</li>
              <li>For compliance purposes, including enforcing our Terms and Conditions or other legal rights, or as may be required by applicable laws and regulations or requested by any judicial process or governmental agency; and</li>
              <li>For other purposes for which we provide specific notice at the time the information is collected.</li>
            </ul>
          </div>
          <h3 className='section-break'>How We Share the Information We Collect</h3>
            <p>We may share or otherwise disclose information we collect from or about you as described below or otherwise disclosed to you at the time of collection.</p>
            <p><b>Associated  Transportation Facility Operators.</b> We share certain information about you, such as your neoRide customer number, LPN, date of enrollment in our Services, toll or other transportation service payment history, or other third-party account number, and other information related to your use of our Services, with ATFOs and OTSPs in connection with transactions related to our Services and in connection with their internal business purposes. </p>
            <p><b>Vendors and Service Providers.</b> Any information you provide to us or that we collect when you use our Services may be shared by us with ATFOs and OTSPs, vendors and service providers (including third parties that support the process for having your toll charges paid) in connection with the provision of our Services, provided that such ATFOs, vendors and service providers may only use such information for the purposes of providing our Services.</p>
            <p><b>Analytics Partners.</b> We use analytics services such as Google Analytics to collect and process certain analytics data. These services may also collect information about your use of other websites, apps, and online resources. You can learn about Google’s practices by going to https://www.google.com/policies/privacy/partners/, and opt-out of them by downloading the Google Analytics opt-out browser add-on, available at https://tools.google.com/dlpage/gaoptout.</p>
            <p><b>As Required by Law and Similar Disclosures.</b> We may access, preserve, and disclose information about you if we believe doing so is required or appropriate to: (a) comply with law enforcement requests and legal process, such as a court order or subpoena; (b) respond to your requests; or (c) protect your, our, or others’ rights, property, or safety.</p>
            <p><b>Merger, Sale, or Other Asset Transfers.</b> We may disclose or transfer your information to service providers, advisors, potential transactional partners, or other third parties in connection with the consideration, negotiation, or completion of a corporate transaction in which we are acquired by or merged with another company or we sell, liquidate, or transfer all or a portion of our business or assets. </p>
            <p><b>Consent.</b> We may also disclose information from or about you or your devices with your permission.</p>
          <h3 className='section-break'>Your Choices</h3>
            <p><b>Marketing Communications.</b> You can unsubscribe from our promotional messages via the method provided in the emails and texts. Even if you opt-out of receiving promotional messages from us, you will continue to receive administrative messages from us.</p>
          <h3 className='section-break'>Third Parties</h3>
            <p>Our Services may contain links to other websites, products, or services that we do not own or operate. We are not responsible for the privacy practices of these third parties. Please be aware that this Privacy Policy does not apply to your activities on these third-party services or any information you disclose to these third parties. We encourage you to read their privacy policies before providing any information to them.</p>
          <h3 className='section-break'>Security</h3>
            <p>We make reasonable efforts to protect your information by using physical and electronic safeguards designed to improve the security of the information we maintain. However, as our Services are hosted electronically, we can make no guarantees as to the security or privacy of your information.</p>
          <h3 className='section-break'>Children's Privacy</h3>
            <p>We do not knowingly collect, maintain, or use personal information from children under 13 years of age, and no part of our Services are directed to children. If you learn that a child has provided us with personal information in violation of this Privacy Policy, then you may alert us at support@TapNpay.com.</p>
          <h3 className='section-break'>International Visitors</h3>
            <p>Our Services are hosted in the United States and intended for visitors located within the United States. If you choose to use our Services from the European Union or other regions of the world with laws governing data collection and use that may differ from U.S. law, then please note that you are transferring your personal information outside of those regions to the United States for storage and processing. Also, we may transfer your data from the U.S. to other countries or regions in connection with storage and processing of data, fulfilling your requests, and operating our Services. By providing any information, including personal information, on or to our Services, you consent to such transfer, storage, and processing.</p>
          <h3 className='section-break'>Update Your Information or Pose a Question</h3>
            <p>You can close your account by deregistering your license plate on the neoRide payment site on the account settings page found here: https://tapnpay.biz/dashboard/profile. If you have questions about your privacy on our Services or this Privacy Policy, please contact us at support@TapNpay.com.</p>
          <h3 className='section-break'>Changes to this Privacy Policy</h3>
            <p>We will post any adjustments to the Privacy Policy on this page, and the revised version will be effective when it is posted. If we materially change the ways in which we use or share personal information previously collected from you through our Services, we will attempt to notify you through our Services, by email, or other means.</p>
          <h3 className='section-break'>Contact Information</h3>
            <p>If you have any questions, comments, or concerns about our processing activities, please email us at support@TapNpay.com or write to us at 1917 Palomar Oaks Way, Suite 110, Carlsbad, CA. 92008. </p>
            <p><b>Last Updated:</b>  August 16, 2023</p>
          
          
          
          {/* <div className="indent">
            <ul>
                <li>The types of information we may collect or that you may provide when you download, install, register with, access, or use the neoRide App (the “App”).</li>
                <li>Our practices for collecting, using, maintaining, protecting, and disclosing that information.</li>
            </ul>
          </div>

          <p>This policy applies only to information we collect in this App and in email, text, and other electronic communications sent through or in connection with this App.
          </p>
          <p>
          </p>
          <p>This policy DOES NOT apply to information that:
          </p>
          <div className="indent">
            <ul>
                <li>We collect offline or on any other Company apps or websites, including websites you may access through this App.</li>
                <li>You provide to or is collected by any third party (see Third-Party Information Collection).</li>
            </ul>
          </div>
          <p>Our websites and apps, and these other third parties have their own privacy policies, which we encourage you to read before providing information on or through them.
          </p>
          <p>
          </p>
          <p>Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, do not download, register with, or use this App. By downloading, registering with, or using this App, you agree to this privacy policy. This policy may change from time to time (see Changes to Our Privacy Policy). Your continued use of this App after we revise this policy means you accept those changes, so please check the policy periodically for updates.
          </p>
          <p></p><p></p>
        
          <h4>Children Under the Age of 16</h4>
          <p>
          The App is not intended for children under 16 years of age, and we do not knowingly collect personal information from children under 16. If we learn we have collected or received personal information from a child under 16 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 16, please contact us at 
          </p>
          <p></p>
          <p></p>
          <h4>Information We Collect and How We Collect It</h4>
          <div className="indent">
            <ul>
                <li>Directly from you when you provide it to us.</li>
                <li>Automatically when you use the App.</li>
            </ul>
          </div>
          <p>Information You Provide to Us<br></br>
             When you download, register with, or use this App, we may ask you provide information:</p>
          <div className="indent">
            <ul>
                <li>By which you may be personally identified, such as name, postal address, email address, telephone number, Toll Tag ID, vehicle license plate number and registration state, vehicle label, and any other identifier by which you may be contacted online or offline (“personal information”).</li>
            </ul>
          </div>
          <p>This information includes:</p>
          <div className="indent">
            <ul>
                <li>Information that you provide by filling in forms in the App. This includes information provided at the time of registering to use the App. We may also ask you for information when you report a problem with the App.</li>
                <li>Records and copies of your correspondence (including email addresses and phone numbers), if you contact us.</li>
                <li>Details of transactions you carry out through the App and of the fulfillment of your orders. You may be required to provide financial information before placing an order through the App.</li>
                <li>Information provided to us by Google or Facebook when you utilize their authentication services to open an account through the App.</li>
            </ul>
          </div>
          <p>Automatic Information Collection and Tracking,<br></br>
             When you download, access, and use the App, it may use technology to automatically collect</p>
          <div className="indent">
            <ul>
                <li>Usage Details. When you access and use the App, we may automatically collect certain details of your access to and use of the App, including traffic data, location data, logs, and other communication data and the resources that you access and use on or through the App.</li>
                <li>Device Information. We may collect information about your mobile device and internet connection, including the device’s unique device identifier, IP address, operating system, browser type, mobile network information, and the device’s telephone number.</li>
                <li>Stored Information and Files. The App also may access metadata and other information associated with other files stored on your device. This may include, for example, photographs, audio and video clips, personal contacts, and address book information.</li>
                <li>Location Information. When enabled by you, this App collects real-time information about the location of your device. The location information collected also consists of activity related to toll use.</li>
                <li>Transaction Activity. We may also collect information about your history of toll transactions as recorded through the applicable Department of Transportation and the App in order to provide services such as activity validation, fee collection and to provide you with reports about your toll usage.</li>
            </ul>
          </div>
          <p>If you do not want us to collect this information, do not download the App or delete it from your device.</p>
          <p>Information Collection and Tracking Technologies<br></br>
             The technologies we use for automatic information collection may include:</p>
          <div className="indent">
            <ul>
                <li>Cookies (or mobile cookies). A cookie is a small file placed on your smartphone. It may be possible to refuse to accept mobile cookies by activating the appropriate setting on your smartphone. However, if you select this setting you may be unable to access certain parts of our App.</li>
                <li>Web Beacons. Pages of the App and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related app statistics (for example, recording the popularity of certain app content and verifying system and server integrity).</li>
            </ul>
          </div>
          <p>Third-Party Information Collection<br></br>
          When you use the App or its content, certain third parties may use automatic information collection technologies to collect information about you or your device. These third parties may include:</p>
          <div className="indent">
            <ul>
                <li>Google Maps. If location services are enabled, your device may share information with Google related to your location and toll, traffic, and road conditions. To opt-out of this collection, you may disable location services in the App.</li>
                <li>Government Agencies. Company may be required to share certain information with the government agencies, for example the Washington Department of Transportation, related to toll activity.</li>
                <li>Advertisers and ad servers.</li>
                <li>Analytics companies, such as Google Analytics</li>
                <li>Your mobile device manufacturer.</li>
            </ul>
            <p>These third parties may use tracking technologies to collect information about you when you use this App. The information they collect may be associated with your personal information or they may collect information, including personal information, about your online activities over time and across different websites, apps, and other online services websites. We do not control these third parties’ tracking technologies or how they may be used.
            </p>
          </div>
          <p></p>
          <p></p>
          <h4>How We Use Your Information</h4>
          <p>We use information that we collect about you or that you provide to us, including any personal information, to:
          </p>
          <div className='indent'>
            <ul>
                    <li>Provide you with the App and its contents, and any other information, products or services that you request from us, including the ability to view and edit personal information.</li>
                    <li>Identify the user of an account, the toll tag, or the vehicle when toll-related services are used.</li>
                    <li>To track toll activity and fees linked to a specific user in order to process your requests.</li>
                    <li>Fulfill any other purpose for which you provide it.</li>
                    <li>Give you notices about your account and to provide you with access to customer support tools, including expiration and renewal notices.</li>
                    <li>Carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.</li>
                    <li>Notify you when App updates are available, and of changes to any products or services we offer or provide though it.</li>
            </ul>
         </div>
          <p>The usage information we collect helps us to improve our App and to deliver a better and more personalized experience by enabling us to:</p>
          <div className='indent'>
            <ul>
                    <li>Estimate our audience size and usage patterns.</li>
                    <li>Store information about your preferences, allowing us to customize our App according to your individual interests</li>
                    <li>Speed up your searches.</li>
                    <li>Recognize you when you use the App.</li>
            </ul>
         </div>
         <p>We use location information we collect to provide you with services such as providing you with information on nearby toll fees, road alerts and conditions, fee collection, activity reports related to your toll use.</p>
         <p></p>
         <p></p>
         <h4>Disclosure of Your Information</h4>
         <p>We may disclose aggregated information about our users, and information that does not identify any individual or device, without restriction.<br></br>
            In addition, we may disclose personal information that we collect or you provide:</p>    
        <div className='indent'>
            <ul>
                    <li>To our subsidiaries and affiliates.</li>
                    <li>To contractors, service providers, and other third parties we use to support our business.</li>
                    <li>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Neology Inc.’s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by Neology, Inc. about our App users is among the assets transferred.</li>
                    <li>To fulfill the purpose for which you provide it.</li>
                    <li>For any other purpose disclosed by us when you provide the information.</li>
                    <li>With your consent.</li>
                    <li>To comply with any court order, law, or legal process, including to respond to any government or regulatory request.</li>
                    <li>To enforce our rights arising from any contracts entered into between you and us, including the App Terms of Use and for billing and collection.</li>
                    <li>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Neology, Inc., our customers or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.</li>
            </ul>
        </div>
        <p></p>
        <p></p>

        <h4>Your Choices About Our Collection, Use, and Disclosure of Your Information</h4>
        <p>We strive to provide you with choices regarding the personal information you provide to us. This section describes mechanisms we provide for you to control certain uses and disclosures of over your information.</p>
        <div className='indent'>
            <ul>
                    <li>Tracking Technologies. You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. You can choose whether or not to allow the App to collect information through other tracking technologies by contacting us at support@neoride.com. If you disable or refuse cookies or block the use of other tracking technologies, some parts of the App may then be inaccessible or not function properly.</li>
                    <li>Location Information. You can choose whether or not to allow the App to collect and use real-time information about your device’s location through the device’s privacy settings or through the APP itself. If you block the use of location information, some parts of the App may become inaccessible or not function properly.</li>
                    <li>Targeted Advertising by the Company. If you do not want us to use information that we collect or that you provide to us to deliver advertisements according to our advertisers’ target-audience preferences, you can opt-out by contacting us at support@neoride.com. You can also always adjust your user advertising preferences by contacting us via the feedback contact form available within the neoRide mobile app.</li>
                    <li>Disclosure of Your Information for Third-Party Advertising and Marketing. If you do not want us to share your personal information with unaffiliated or non-agent third parties for advertising and marketing purposes, you can opt-out by contacting us at support@neoride.com. You can also always opt-out by contacting us via the feedback contact form available within the neoRide mobile app.</li>
            </ul>
        </div>
        <p>We do not control third parties’ collection or use of your information to serve interest-based advertising. However these third parties may provide you with ways to choose not to have your information collected or used in this way. You can opt out of receiving targeted ads from members of the Network Advertising Initiative (“NAI”) on the NAI’s website.</p>
        
        <p></p>
        <p></p>
        <h4>Accessing and Correcting Your Personal Information</h4>
        <p>You can review and change your personal information by logging into the App and visiting your account profile page.<br></br>
           You may also contact us via the feedback contact form available within the neoRide mobile app or contacting Customer Support: support@neoride.com.</p>
        <p></p>
        <p></p>
        <h4>Data Security</h4>
        <p>We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure.<br></br>
        The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our App, you are responsible for keeping this password confidential. We ask you not to share your password with anyone.
        Unfortunately, the transmission of information via the internet and mobile platforms is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted through our App. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures we provide.
        </p>
        <p></p>
        <p></p>
        <h4>Changes to Our Privacy Policy</h4>
        <p>We may update our privacy policy from time to time. If we make material changes to how we treat our users’ personal information, we will post the new privacy policy on this page with a notice that the privacy policy has been updated the next time you use the App after we make the change.<br></br>
        The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date active and deliverable email address for you and for periodically visiting this privacy policy to check for any changes.
        </p>
        <p></p>
        <p></p>
        <h4>Changes to Our Privacy Policy</h4>
         <p>To ask questions or comment about this privacy policy and our privacy practices, contact us at:</p>
         <p>1917 Palomar Oaks Way, Suite 110<br></br>
            Carlsbad, CA 92008</p> */}
         
         
         </div>


        
      </section>

      <Footer />
      </>
    )
  }
}
export default Privacy