import {useState} from 'react'
import axios from 'axios';

const ContactSend = () => {
    const [formData, setFormData] = useState({
        fromLName: '',
        fromFName: '', 
        fromEmail: '',
        phone: '',
        company: 'N/A',
        subject: 'N/A',
        content: ''
    })

    function formInputChange(e, str) {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [str]: e.target.value
        }))
    }

    const invokeLambda = async () => {
        const response = await axios.post('https://c52ru7efob.execute-api.us-west-1.amazonaws.com/sendemail', formData, {headers: {'Content-Type': 'application/json'}})
                                    .then(res => {
                                        console.log(res.data);
                                        console.log("Client: Promise resolved!");
                                        alert(`Your message was sent successfully.`); 
                                    })
                                    .catch(err => {
                                        console.log('error: ', err);
                                        console.log("Client: Failed to send request to Lambda.");
                                        alert(`Message failed to send. Try again later.`);
                                    });
        resetForm();
       }
    function resetForm(){
    setFormData(prevFormData => ({
        ...prevFormData,
        fromLName: '',
        fromFName: '', 
        fromEmail: '',
        phone: '',
        company: 'N/A',
        subject: 'N/A',
        content: ''
    }))
    }

    return (
        <div className='col-md-12 col-lg-5 mx-auto' >
            <div className='mb-3 text-center'>
                <h1 className='contact-form-title'>Contact Form</h1>
            </div>
            <div class="mb-3 text-start">
                <label for="exampleFormControlInput1" className="form-label">Name</label>
                <input type="email" class="form-control" id="exampleFormControlInput1" value={formData.name} onChange={(e) => formInputChange(e, 'fromFName')} placeholder="John Smith" />
            </div>
            <div class="mb-3 text-start">
                <label for="exampleFormControlInput1" className="form-label">Phone</label>
                <input type="email" class="form-control" id="exampleFormControlInput1" value={formData.phone} onChange={(e) => formInputChange(e, 'phone')} placeholder="123-456-7890" />
            </div>
            <div class="mb-3 text-start">
                <label for="exampleFormControlInput1" className="form-label">Email address</label>
                <input type="email" class="form-control" id="exampleFormControlInput1" value={formData.email} onChange={(e) => formInputChange(e, 'fromEmail')} placeholder="name@example.com" />
            </div>
            <div class="mb-3 text-start">
                <label for="exampleFormControlTextarea1" className="form-label">Message</label>
                <textarea class="form-control" id="exampleFormControlTextarea1" value={formData.content} onChange={(e) => formInputChange(e, 'content')}  rows="3" ></textarea>
            </div>
            <div class="mb-3 text-start">
                <button type="button" class="btn btn-submit" onClick={invokeLambda}>Submit</button>
            </div>
        </div>
    )

}
export default ContactSend;