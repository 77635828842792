
import React from 'react'
import {BrowserRouter,Routes,Route} from "react-router-dom";
import Page from './App/components/page';
// import AboutUs from './App/pages/about-us';
// import Contact from './App/pages/contact';
import Home from './App/pages/home';
import Privacy from './App/pages/privacy';
import NotFound from './App/pages/not-found'
import AppleAppSite from './App/pages/apple-app-site-association';

function RoutesApp(){
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<Page/>} >
                    <Route path="/" element={<Home />} />
                    {/* <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/contact" element={<Contact />} /> */}
                    <Route path="/privacy" element={<Privacy />} />
                    <Route path="/apple-app-site-association" element={<AppleAppSite />} />
                </Route> 

                {/*----------------*/}
                <Route path='*' element={<NotFound/>}/>

            </Routes>
        </BrowserRouter>
    );
}
export default RoutesApp;
