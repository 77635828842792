import React, { Component } from 'react'
import Logo from "../../assets/images/nav__logo.png"
import NeorideLogo from '../../assets/images/logo.png';
import { HashLink } from 'react-router-hash-link';

export default class Footer extends Component {
  render() {
    return (
      <footer className='footer-vado  background-theme'>
        <div className='col-12 row footer-vado-start justify-content-center' style={{marginLeft: '50px', marginRight: '50px', width: 'auto'}}>
          <div className='col-lg-4 col-md-4' style={{paddingTop: '65px'}}>
            <img className='logo' src={NeorideLogo} style={{display: 'block', marginRight: 'auto', width: '85px', paddingBottom: '10px', height: 'auto'}}></img>
            <p className='text-items-footer' style={{display: 'block'}}>
            © 2023 neoRide All Rights Reserved.
            </p>
          </div>
      
          <div className='col-lg-6 col-md-4 d-flex flex-column'>
            <h4 className='footer-vado-title text-end'>Sitemap</h4>
            <HashLink className='footer-vado-links text-end' to="/#coverage">Coverage</HashLink>
            <HashLink className='footer-vado-links text-end' to="/#coverage">Onboarding</HashLink>
            {/* <a className='footer-vado-links text-end'>FAQS</a> */}
            <a className='footer-vado-links text-end' href="/privacy">Privacy Policy</a>
          </div>
          {/* <div className='col-lg-2 col-md-4 d-flex flex-column disappear'>
            <h4 className='footer-vado-title text-end'>Services</h4>
                <div className='d-flex flex-column'>
                  <a className='footer-vado-links text-end' href="/solutions/#1">Toll Payments</a>
                  <a className='footer-vado-links text-end' href="/solutions/#parking">Parking</a> */}
                  {/*<a className='footer-vado-links text-end' href="/solutions">Low-Emission Zones</a> */}
                {/* </div> */}
                {/* <div className='d-flex flex-column ms-5 disappear'>
                  <a className='footer-vado-links'>Fleet Management</a>
                  <a className='footer-vado-links'>Gas Payments</a>
                  <a className='footer-vado-links'>Ev Charging</a>
                </div> */}
          {/* </div> */}
            {/* <div className='col-lg-3 col-md-4 d-flex flex-column disappear'>
              <h4 className='footer-vado-title text-end'>Follow us</h4>
              <a className='footer-vado-links text-end'>Linkedin</a>
              <a className='footer-vado-links text-end'>Facebook</a>
              <a className='footer-vado-links text-end'>Instragram</a>
            </div> */}
          
        </div>
        {/* <div className='d-flex justify-content-end py-4 footer-element-end w-max'>
        <p className='text-items-footer'>
        © 2022 VADO All Rights Reserved.
        </p>
        </div> */}
      </footer>
    )
  }
}
