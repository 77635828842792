import { useMediaQuery } from '@mui/material';
import React, { Component } from 'react'
import { useNavigate } from 'react-router-dom';
import Logo from "../../assets/images/nav__logo.png"
import LogoText from "../../assets/images/vadologotext.png"
import { FiMenu } from "react-icons/fi";
import VadoLogoNew from "../../assets/images/vadologonew.PNG"
import NeorideLogo from '../../assets/images/logo.png';
import $ from 'jquery';
import { HashLink } from 'react-router-hash-link';


class ContentHeader extends Component {

  constructor(props) {
    super(props)

    this.state = {
      color: props.color
    }
  }

  componentDidMount() {
  console.log(this.props.color)
  $(function () {
    $(document).scroll(function () {
      var $nav = $(".navbar");
      var $section = $(".terms-header")
      var $navlink = $('.nav-link');
      console.log($section.height())
      if ($section.height() == null) {
        console.log('null');
        $nav.toggleClass('scrolled', $section.height() == null);
      } else {
        $nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height());

      }
    });
  });

  }

  render() {
    let location = window.location.pathname
    return (
      <nav className="navbar navbar-expand-md fixed-top " style={{backgroundColor: '#transparent', height:'100px'}}>
        <div className="container-fluid" style={{backgroundColor: "#transparent"}}>
          <div style={{marginLeft: '0px', marginBottom: '0px'}}>
            <a href='/'>
              {/* <img className='logo' src={NeorideLogo}></img> */}
              <img className='logo-text' src={NeorideLogo}></img>
            </a>
          </div>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse text-end content-end" id="navbarCollapse">
            <ul className="navbar-nav ms-auto mb-2 mb-md-0 px-2" style={{background: '#transparent'}}>
              <li className="nav-item">
              <HashLink className='nav-link nav-toggler' to="/#home">Home</HashLink>
              </li>
              <li className="nav-item">
              <HashLink className='nav-link nav-toggler' to="/#coverage">Coverage</HashLink>
              </li>
              <li className="nav-item">
              <HashLink className='nav-link nav-toggler' to="/#onboarding">Onboarding</HashLink>
              </li>
              <li className="nav-item">
              <HashLink className='nav-link nav-toggler' to="/#contact">Contact Us</HashLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    )
  }
}

function Header(props) {
  let navigate = useNavigate();
  const matches = useMediaQuery('(min-width:768px)');
  return <ContentHeader {...props}  mobile={matches} navigate={navigate} />;
}
export default Header;
