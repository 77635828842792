import React, { Component } from 'react'
import { Outlet } from 'react-router-dom';
import Footer from './footer';
import Header from './header';

export default class Page extends Component {
  render() {
    return (
      <div className='d-flex justify-content-between flex-column' style={{minHeight:"100vh"}}>
        {/* <Header /> */}
        <Outlet />
        {/* <Footer /> */}
      </div>
    )
  }
}
