import React  from 'react';
import './App.css';
import RoutesApp from './routes';

function App() {
   //some dev change
  return (
    <RoutesApp/>
  );
}

export default App;
