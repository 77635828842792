import React, { Component } from 'react'
import Footer from './../components/footer';

class NotFound extends Component {
  render() {
    return (
      <div className='content-center'>
        <h2>Page Not Found</h2>
      </div>
    )
  }
}
export default NotFound;
