import React, { Component } from 'react'

import Apple from "../../assets/images/apple.png";
import GooglePlay from "../../assets/images/google-play.png";
import Header from '../components/header';
import ContactSend from '../components/contact-send';
import '../../styles/home.scss';
import '../../styles/map.scss';
import '../../styles/contact-us.scss';
import '../../styles/onboarding.scss';
import TapNPay from '../../assets/images/tapnpay-logo.png';
import Footer from './../components/footer';
import SVGCar from '../../assets/images/svg-car.png';
import SVGPhone from '../../assets/images/svg-phone.png';
import SVGPayment from '../../assets/images/svg-payment.png';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom'
import Map from "../components/map"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import TNP from '../../assets/images/tapnpay.png';

class ContentHome extends Component {

  constructor(props) {
    super(props)


  }
  state = {
    windowWidth: window.innerWidth
  }

  updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth });
    console.log(window.innerWidth)
  };
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);

  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }



  render() {
    console.log(this.props.mobile)

    return (
      <>
        <section id='home' className='home-header' style={{ backgroundPosition: this.state.windowWidth <= 576 ? '47.5% 30%' : '50% 20%' }}>
          <Header color='white' />
          <div className="position-relative overflow-hidden text-center vado-banner" style={{ paddingLeft: '20px', paddingRight: '20px', minHeight: '100vh' }}>
            {/* <div className="bg-image " style={{backgroundImage: `url(${SeattlePicTwo})`, backgroundPosition: this.state.windowWidth <= 576 ? '20% 30%' : '50% 20%'}} ></div> */}
            <div className="col-md-6 p-lg-6 mx-auto banner-text-grey banner-text-grey-margin">
              <h1 className="display-4 fw-normal banner-header">Get Back to the Open Road.</h1>
              <p className="lead fw-normal banner-subtext">Toll payment services, parking, road user charging.  All on one platform.</p>
            </div>
            {/* <div className="col-md-6 p-3 mt-3 mb-5 mx-auto banner-text-grey" style={{background: 'RGBA(255,255,255,0.35', borderRadius: '10px'}}>
              <p className="lead fw-bold">If you prefer, you may register for our Post-Paid toll payment solution using text (no app needed).​</p>
              <div className='d-flex flex-row justify-content-center'>
                <a href="https://tapnpay.info" target="_blank">
                    <img className='shadow-image me-4 mt-1' src={TapNPay}></img>
                </a>
              </div>
              <p className='lead fw-normal mt-2'>Tap above to be directed to our tapNpay website for more information</p>
            </div> */}
            <div className="product-device shadow-sm d-none d-md-block"></div>
            <div className="product-device product-device-2 shadow-sm d-none d-md-block"></div>
          </div>
        </section>

        <section id='coverage' >
          <div className='container map-header'>
            <div className='row'>
              <div className='col-md-6'>
                <h1 className='overview-title-text text-center' >Coverage Map</h1>
                <p className='text-center' id='overview-sub'>Download our app or if you prefer, you may register for our Post-Paid toll payment solution using text (no app needed).​</p>
              </div>
            </div>
          </div>
          <div className='container '>
            <div className='row '>
              <div className='col-md-12 d-flex justify-content-center my-5 '>
                <a href="https://apps.apple.com/us/app/vado-mobility/id1545182574" target="_blank">
                  <img className='outbound-button shadow-image mx-2' src={Apple}></img>
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.neology.vado&hl=en_US&gl=US" target="_blank">
                  <img className='outbound-button shadow-image mx-2' src={GooglePlay}></img>
                </a>
                <a href="https://tapnpay.info" target="_blank">
                  <img className='outbound-button shadow-image mx-2' src={TNP} ></img>
                </a>
              </div>
            </div>
          </div>
          <Map></Map>
        </section>

        <section className='home-overview' id='onboarding'>
          <div className="container-fluid px-4 py-5 align-items-center justify-content-between text-center" id='onboarding-div'>
            <div id='tolling-onboarding-title-container'>
              <h1 className='overview-title-text'>Easy Onboarding</h1>
              <p>Start driving in a matter of minutes.</p>
            </div>
            <div className='row justify-content-between text-center mx-auto' id="tolling-onboarding">
              <div className='card tolling-card col-10 col-lg-4 col-xl-4 mx-auto'>
                  <div className="card-body card-holder">
                    <h5 className="card-title my-3">1.  Register by Phone</h5>
                    <img className='onboarding-image mt-lg-5 mt-md-3' src={SVGPhone}/>
                  </div>
              </div>
              <div className='card tolling-card col-10 col-lg-4 col-xl-4 mx-auto'>
                  <div className="card-body">
                    <h5 className="card-title my-3">2.  Add your Vehicle</h5>
                    <div className='card-image'>
                      <img className='onboarding-image mt-lg-5 mt-md-3' src={SVGCar}/>
                    </div>
                  </div>
              </div>
              <div className='card tolling-card col-10 col-lg-4 col-xl-4 mx-auto text-center'>
                  <div className="card-body">
                    <h5 className="card-title my-3">3.  Add Payment</h5>
                    <img className='onboarding-image mt-lg-5 mt-md-3' src={SVGPayment}/>
                  </div>
              </div>
              {/* <div className='col-10 col-lg-5 col-xl-3 mx-auto'>
                <div className="card tolling-card mx-auto">
                  <div className="card-body">
                    <img className='onboarding-image' src={SVGMap}/>
                    <h5 className="card-title">Hit The Road!</h5>
                    <p className="card-text onboarding-subtext text-center">From start to finish in no time.</p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>
        <section id='contact'>
          <div className='container-fluid px-4 py-5 d-flex justify-content-center align-items-center text-center' id='contact-title-banner'>
            <div className='d-flex flex-column text-center'>
                <h1 id='contact-title' className='contact-title-text'>Contact Us</h1>
            </div>
          </div>
          <div id='contact-body' className='container-fluid px-4 py-5 mt-5'>
            <div className='row mx-auto align-items-center'>
              <div id='contact-info-div' className='col-md-12 col-lg-4 mx-auto d-flex flex-column'>
                <div className={`justify-content-start ${this.state.windowWidth <=993 ? 'text-start' : 'text-center'} text-lg-start text-xl-start text-xxl-start`}>
                  <h1 className='contact-info-title'>Have any questions?</h1>
                </div>
                <div className='d-flex justify-content-start justify-content-lg-start justify-content-xl-start justify-content-xxl-start'>
                  <FontAwesomeIcon icon={faPhone} size='lg' style={{marginRight: '20px'}}/>
                  <h5 className='contact-info-text'> +1 512-649-3062</h5>
                </div>
                <div className='d-flex justify-content-start justify-content-lg-start justify-content-xl-start justify-content-xxl-start'>
                  <FontAwesomeIcon icon={faEnvelope} size='lg' style={{marginRight: '20px'}}/>
                  <h5 className='contact-info-text'> support@neoride.com</h5>
                </div>
                <div className='d-flex justify-content-start justify-content-lg-start justify-content-xl-start justify-content-xxl-start'>
                  <FontAwesomeIcon icon={faLocationDot} size='lg' style={{marginRight: '20px'}}/>
                  <div className='flex-column'>
                    <h5 className='contact-info-text'>1917 Palomar Oaks Way Suite 110</h5>
                    <h5 className='contact-info-text'>Carlsbad, CA 92008</h5>
                  </div>
                </div>
              </div>
              <ContactSend />
            </div>
          </div>
        </section>

        <Footer />
      </>
    )
  }
}

function Home(props) {
  let navigate = useNavigate();
  const matches = useMediaQuery('(min-width:768px)');
  return <ContentHome {...props} mobile={matches} navigate={navigate} />;
}
export default Home;
